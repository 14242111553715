import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@material-ui/core';
import { ThemeProvider } from "styled-components"
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';

import Account from './pages/Account';
import Extractions from './pages/Extractions';
import Login from './pages/Login';
import NotFound from './pages/NotFound';


import { isLoggedIn } from "./utils/auth"

function RequireAuthAdmin({ children }: { children: JSX.Element }) {
  let location = useLocation();
  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

function RequireAuthScanApp({ children }: { children: JSX.Element }) {
  let location = useLocation();
  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

function GoToHome({ children }: { children: JSX.Element }) {
  let location = useLocation();
  if (isLoggedIn()) {
    return <Navigate to="/app/extractions" state={{ from: location }} />;
  } else {
    return <Navigate to="/login" state={{ from: location }} />;
  }
}

function GoTo404({ children }: { children: JSX.Element }) {
  let location = useLocation();
  return <Navigate to="/404" state={{ from: location }} />;
}

function AlwaysLoginRedirect({ children }: { children: JSX.Element }) {
  let location = useLocation();
  if (isLoggedIn()) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Routes>
            <Route exact path='/login' element={
              <AlwaysLoginRedirect>
                <MainLayout>
                  <Login/>
                </MainLayout>
              </AlwaysLoginRedirect>
            }/>
            <Route exact path='/404' element={
              <MainLayout>
                <NotFound/>
              </MainLayout>
            }/>


            <Route exact path="/" element={
              <GoToHome/>
            }/>
            <Route exact path="/app/account" element={
              <RequireAuthAdmin>
                <DashboardLayout>
                  <Account />
                </DashboardLayout>
              </RequireAuthAdmin>
            }/>
            <Route exact path="/app/extractions" element={
              <RequireAuthAdmin>
                <DashboardLayout>
                  <Extractions />
                </DashboardLayout>
              </RequireAuthAdmin>
            }/>

            <Route exact path='*' element={
              <GoTo404/>
            }/>
          </Routes>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

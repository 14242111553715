import { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import {
  User as UserIcon,
  UserPlus as UserPlusIcon,
  FileText as FileTextIcon,
  PlusCircle as PlusCircleIcon,
  LogOut as LogOutIcon
} from 'react-feather';
import NavItem from './NavItem';

import MyModal from './ui/MyModal'
import MyModalSmall from './ui/MyModalSmall'

import APIS from "../utils/APIS"
import { getCurrentUser, logout } from "../utils/auth"

export const passwordValidation = {
  required: "Veuillez entrer un mot de passe",
  regex: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*,-.:\;<>?@[\]^_`{|}~])/,
  matches:
    "Doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et un caractère spécial",
  majMinRegex: /(?=.*[a-z])(?=.*[A-Z])/,
  majMinMatches: "Doit contenir au moins 1 majuscule et 1 minuscule",
  digitSpecialCharRegex: /(?=.*\d)(?=.*[ !"#$%&'()*,-.:\;<>?@[\]^_`{|}~])/,
  digitSpecialCharMatches:
    "Doit contenir au moins 1 chiffre et un caractère spécial (les symboles + et = ne sont pas valides)",
  min: "Doit contenir au moins 8 caractères",
}


const items = [
  {
    href: '/app/extractions',
    icon: FileTextIcon,
    title: 'Extractions'
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const currentUser = getCurrentUser()
  const location = useLocation();
  const navigate = useNavigate();

  const [dialogAddAccount, setDialogAddAccount] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          <NavItem
            href='/app/account'
            title='Mon compte'
            icon={UserIcon}
          />
          <Hidden lgUp>
            <NavItem
              title='Déconnexion'
              icon={LogOutIcon}
              onClick={() => {
                logout(() => navigate("/"))
              }}
            />
          </Hidden>
          {currentUser.super === "true" &&
            <NavItem
              title='Ajouter un compte'
              icon={PlusCircleIcon}
              onClick={() => {setDialogAddAccount(true)}}
            />
          }
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <MyModal
        open={dialogAddAccount}
        onClose={() => {
          setDialogAddAccount(false);
        }}
        title="Ajouter un compte"
        validButton={false}
      >
        <Formik
          initialValues={{
            email: '',
            password:'',
            firstName: '',
            lastName: '',
            society : '',
          }}
          validationSchema={
          Yup.object().shape({
            email: Yup.string().email('Doit être un email valide').max(255).required("L'email est requis"),
            password: Yup.string()
              .min(8, passwordValidation.min)
              .matches(passwordValidation.regex, passwordValidation.matches)
              .required(passwordValidation.required),
            firstName: Yup.string().max(255).required('Le prénom est requis'),
            lastName: Yup.string().max(255).required('Le nom est requis'),
            society: Yup.string().max(255).required('La société est requise'),
          })
        }
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const currentDate = new Date(
              new Date().getTime() - new Date().getTimezoneOffset() * 60000
            ).toISOString()
            const params = {
              firstName: values.firstName,
              lastName : values.lastName,
              email: values.email,
              society : values.society,
              password : values.password,
              createdAt : currentDate,
              updatedAt : currentDate,
            }

            APIS.addAccount(params).then(async (result) => {
              if (result && result.message === "addAccount") {
                setSubmitting(false);
                setDialogAddAccount(false);

                alert("Compte créé")

              } else {
                alert("Erreur")
                setSubmitting(false);
              }
            });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.society && errors.society)}
                fullWidth
                helperText={touched.society && errors.society}
                label="Sa société"
                margin="normal"
                name="society"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.society}
                variant="outlined"
                size='small'
              />
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                label="Son Nom"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="outlined"
                size='small'
              />
              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                label="Son Prénom"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="outlined"
                size='small'
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Son Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                size='small'
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Mot de passe"
                margin="normal"
                name="password"
                type="password"
                variant="outlined"
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  CREER
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </MyModal>



      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;

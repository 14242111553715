import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import ExtractionsListResults from '../components/extractions/ExtractionsListResults';

const ExtractionsList = () => (
  <>
    <Helmet>
      <title>Extractions | RisingUp Certif</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <ExtractionsListResults/>
      </Container>
    </Box>
  </>
);

export default ExtractionsList;

import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { getCurrentUser } from "../../utils/auth"
import APIS from "../../utils/APIS"

export const passwordValidation = {
  required: "Veuillez entrer un mot de passe",
  regex: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !"#$%&'()*,-.:\;<>?@[\]^_`{|}~])/,
  matches:
    "Doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et un caractère spécial",
  majMinRegex: /(?=.*[a-z])(?=.*[A-Z])/,
  majMinMatches: "Doit contenir au moins 1 majuscule et 1 minuscule",
  digitSpecialCharRegex: /(?=.*\d)(?=.*[ !"#$%&'()*,-.:\;<>?@[\]^_`{|}~])/,
  digitSpecialCharMatches:
    "Doit contenir au moins 1 chiffre et un caractère spécial (les symboles + et = ne sont pas valides)",
  min: "Doit contenir au moins 8 caractères",
}

const SettingsPassword = (props) => {

  const currentUser = getCurrentUser()

  const [passwdChangedMessage, setPasswdChangedMessage] = useState("")

  return (
    <Card>
      <CardHeader
        subheader=""
        title="Modifier votre mot de passe"
      />
      <Divider />
        <Formik
          initialValues={{
            password: '',
            confirm : '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(8, passwordValidation.min)
              .matches(passwordValidation.regex, passwordValidation.matches)
              .required(passwordValidation.required),
            confirm: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre')
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const handlePasswordSubmit = () => {
              APIS.postSetPsswd({
                password: values.password,
                confirm : values.confirm,
              }).then(result => {
                if (result.message == "ok") {
                  setSubmitting(false);
                  resetForm({})
                  setPasswdChangedMessage("Mot de passe modifié !")
                  setTimeout(function(){
                    setPasswdChangedMessage("")
                  }, 4000);
                } else {
                  resetForm({})
                  setSubmitting(false);
                  alert("Une erreur est survenue")
                }
              })
            }
            handlePasswordSubmit()
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <CardContent>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Mot de passe"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                />
                <TextField
                  error={Boolean(touched.confirm && errors.confirm)}
                  fullWidth
                  helperText={touched.confirm && errors.confirm}
                  label="Confirmation"
                  margin="normal"
                  name="confirm"
                  type="password"
                  variant="outlined"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirm}
                />
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems : 'center',
                    p: 2
                  }}
                >
                  <Typography style={{color:'green'}}>
                    {passwdChangedMessage}
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    MODIFIER
                  </Button>
                </Box>
              </form>
          )}
        </Formik>
    </Card>
  );
};

export default SettingsPassword;

import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components"
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
  Hidden,
} from '@material-ui/core';
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import DialogActions from '@material-ui/core/DialogActions';
import {
  Search as SearchIcon,
  RefreshCw as RefreshCwIcon,
  Edit as EditIcon,
  PieChart as PieChartIcon,
  BookOpen as BookOpenIcon,
  Trash2 as TrashIcon,
  PlusCircle as PlusCircleIcon,
  CheckCircle as CheckCircleIcon,
} from 'react-feather';
import getInitials from '../../utils/getInitials';
import MyModal from '../ui/MyModal'
import MyModalSmall from '../ui/MyModalSmall'
import socketio from "socket.io-client";

import { Radar } from "react-chartjs-2";

import isEmail from 'validator/lib/isEmail';

import APIS, {glb_url} from "../../utils/APIS"
import { getCurrentUser } from "../../utils/auth"
import { skillsDefs } from "../../utils/skillsDefs"

const ListResultString = styled.p`
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  margin: 0;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const Select = styled(TextField)`
  margi-bottom: 10px;
  && label {
    color : ${props => props.error ? "red" : "#6b778c"};
  }
`

const ExtractionsListResults = ({ ...rest }) => {

  const currentUser = getCurrentUser()

  const onUploadProgress = (progressEvent) => {
    let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    setProgressAdd(progress)
  }

  /* SOCKET */
  const [socket, setSocket] = useState(null)
  const renewSocket = () => {
    if (socket) {
      socket.disconnect().connect()
    }
  }
  useEffect(() => {
    if (socket) {
      const connectedEvent = data => {
        console.log("sio properly connected")
      }

      const updateExtractionEvent = data => {
        getExtractions()
      }

      socket.on("connected", connectedEvent)
      socket.on("updateExtraction", updateExtractionEvent)

      return () => {
        socket.off("connected", connectedEvent)
        socket.off("updateExtraction", updateExtractionEvent)
      }
    }
  }, [socket])
  /* END SOCKET */

  useEffect(() => {
    setSocket(
      socketio.connect(glb_url, {
        withCredentials : true,
      })
    )
  }, [])

  /* EDIT EXTRACT */
  const [dialogEdit, setDialogEdit] = useState(false)
  const [dialogEditResult, setDialogEditResult] = useState(null)
  const [dialogAddSkill, setDialogAddSkill] = useState(false)
  const [selectAddSkill, setSelectAddSkill] = useState(null)
  const [skillsDispo, setSkillsDispo] = useState([])
  useEffect(() => {
    if (dialogEdit !== false) {
      APIS.getResultByID({extractionID : dialogEdit.id}).then(result => {
        if (result?.message === "resultByID" && result?.result) {
          let find = extractionsNoFiltered?.find((el) => el.id === dialogEdit.id)
          let actIndex = 0
          let nbrBlocs = result?.result?.firstResult?.blocs.length
          if (find) {
            actIndex = Math.round((find.progressModif/100)*nbrBlocs)
            if (actIndex > nbrBlocs-1) {
              actIndex = nbrBlocs-1
            }
          }
          result.result.edition = {
            actIndex : actIndex,
            nbrBlocs : nbrBlocs
          }
          result?.result?.firstResult?.blocs.forEach((bloc) => {
            if (!bloc.profile) {
              bloc.profile = {}
            }
          })
          result?.result?.modifResult?.forEach((bloc) => {
            if (!bloc.profile) {
              bloc.profile = {}
            }
          })
          //on cherche si on a des skills dans modifResult qui ne sont pas dans firstResult
          result?.result?.modifResult?.forEach((bloc, index) => {
            let firstResultBlocs = result?.result?.firstResult?.blocs[index]
            Object.keys(bloc.profile).forEach((profile) => {
              let find = firstResultBlocs.profile[profile]
              if (!find) {
                firstResultBlocs.profile[profile] = 0
              }
            })
          });
          setDialogEditResult(result?.result)
        }
      })
    } else {
      setDialogEditResult(null)
    }
  }, [dialogEdit])

  useEffect(() => {
    //console.log(dialogEditResult)
    if (dialogEditResult) {
      let skills = JSON.parse(JSON.stringify(skillsDefs))
      let actSkills = Object.keys(dialogEditResult?.firstResult?.blocs[dialogEditResult?.edition?.actIndex]?.profile)
      skills = skills.filter((el) => {
        return !actSkills.some((el2) => el2 === el.name)
      })
      setSkillsDispo(skills)
      setSelectAddSkill(skills[0]?.name)
    }

  }, [dialogEditResult])
  /* END EDIT EXTRACT */

  /* VIEW GRAPH */
  const [dialogGraph, setDialogGraph] = useState(false)
  const [dialogGraphResult, setDialogGraphResult] = useState(null)
  useEffect(() => {
    if (dialogGraph !== false) {
      APIS.getResultByID({extractionID : dialogGraph.id}).then(result => {
        if (result?.message === "resultByID" && result?.result) {
          setDialogGraphResult(result?.result)
        }
      })
    } else {
      setDialogGraphResult(null)
    }
  }, [dialogGraph])
  /* END VIEW GRAPH */

  /* ADD EXTRACT */
  const [dialogAddExtract, setDialogAddExtract] = useState(false);
  const [disabledAdd, setDisabledAdd] = useState(false);
  const [progressAdd, setProgressAdd] = useState(0)
  /* END ADD EXTRACT */

  /* SEARCH */
  const [searchInput, setSearchInput] = useState("")
  const [extractionsNoFiltered, setExtractionsNoFiltered] = useState([])
  useEffect(() => {
    if (searchInput !== "") {
      setExtractions(extractionsNoFiltered.filter(item => {
        if (
          item.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1
        ) {
          return true
        }
      }))
    } else {
      setExtractions(extractionsNoFiltered)
    }
  }, [extractionsNoFiltered, searchInput])
  /* END SEARCH */


  const [isLoading, setIsLoading] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [extractions, setExtractions] = useState([])

  const getExtractions = () => {
    setIsLoading(true)
    APIS.getExtractions({}).then(results => {
      if (results.message == "allExtractions" && results.extractions) {
        setExtractionsNoFiltered(results.extractions);
        setIsLoading(false)
        setIsRefreshing(!isRefreshing)
      }
    })
  }
  useEffect(() => {
    getExtractions()
  }, [])

  const getDialogEditTitle = () => {
    let actIndex = dialogEditResult?.edition?.actIndex+1
    let nbrBlocs = dialogEditResult?.edition?.nbrBlocs

    let find = extractionsNoFiltered?.find((el) => el.id === dialogEdit.id)
    let progressModif = find?.progressModif || 0

    let actPercentProgress = (actIndex/nbrBlocs)*100

    return (
      <Box sx={{display:"flex", gap:"10px", alignItems:"center"}}>
        {(dialogEdit !== false && dialogEditResult !== null) ? "Edition \""+dialogEdit?.name+"\" (Bloc "+actIndex+"/"+nbrBlocs+")" : "Edition"}
        {progressModif >= actPercentProgress &&
          <CheckCircleIcon color="green" size={20} />
        }
      </Box>
    )
  }

  const getLabelForGraph = (datas) => {
    let newArr = []
    datas.forEach((profile) => {
      let displayName = skillsDefs?.find((el) => el.name === profile)?.title
      newArr.push(displayName)
    })
    return newArr
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'flex-start',
          }}
        >
          <Box sx={{flex:1}}>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              <b>
                Extractions : {extractionsNoFiltered.length}
              </b>
            </Typography>
          </Box>
          <Box sx={{textAlign:'right', flex:[0, 1] }}>
            <Button
              sx={{ minWidth:'110px' }}
              color="primary"
              variant="contained"
              onClick={() => {setDialogAddExtract(true)}}
            >
              + AJOUTER
            </Button>
          </Box>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent style={{paddingBottom:'unset', padding:'16px'}}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems:'center',
              }}>
                <Box sx={{ maxWidth: '500px', width:'100%' }}>
                  <TextField
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    disabled={extractionsNoFiltered && extractionsNoFiltered.length > 0 ? false : true}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Chercher ..."
                    variant="outlined"
                  />
                </Box>
                <Box style={{display:'flex',marginLeft:'50px'}}>
                  {isLoading ? (
                    <img
                      alt="loading"
                      src="/static/loading.gif"
                      height="24px"
                      width="24px"
                    />
                  ) : (
                    <RefreshCwIcon
                      style={{
                        cursor:'pointer',
                        transitionDuration: '0.5s',
                        transitionProperty: 'transform',
                        transform: isRefreshing ? 'rotate(181deg)' : 'rotate(0deg)',
                      }}
                      onClick={() => {getExtractions();setIsRefreshing(!isRefreshing)}}
                    />
                  )}
                </Box>

              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>

      <Box sx={{ pt: 3 }}>
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 900 }}>
              <Table>
                <TableHead>
                  <TableRow style={{height:'64px'}}>
                    <TableCell style={{padding:"10px"}}>
                      Nom
                    </TableCell>
                    <TableCell style={{padding:"10px"}}>
                      Approche
                    </TableCell>
                    <TableCell style={{padding:"10px"}}>
                      PDF
                    </TableCell>
                    <TableCell style={{padding:"10px"}}>
                      Date/Heure
                    </TableCell>
                    <TableCell style={{padding:"10px"}}>
                      Expert
                    </TableCell>
                    <TableCell style={{width:'100px', paddingLeft:'0px', paddingRight:'0px', textAlign:'center'}}>
                      Status
                    </TableCell>
                    <TableCell style={{width:'65px', paddingLeft:'0px', paddingRight:'0px', textAlign:'center'}}>
                      Editer
                    </TableCell>
                    <TableCell style={{width:'65px', paddingLeft:'0px', paddingRight:'0px', textAlign:'center'}}>
                      Graph
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extractions.map((extract) => (
                    <TableRow
                      hover
                      key={extract.id}
                    >
                      <TableCell style={{padding:"10px"}}>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {extract.name}
                        </Typography>
                      </TableCell>
                      <TableCell style={{padding:"10px"}}>
                        {extract.method === "semantic" && "Sémantique"}
                        {extract.method === "zeroshot" && "Zéro-Shot"}
                      </TableCell>
                      <TableCell style={{padding:"10px"}}>
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          style={{height:'31px', minWidth:'0px'}}
                          disabled={false}
                          onClick={() => {
                            window.open(glb_url+"/files/extractions/"+extract.file);
                          }}
                        >
                          <BookOpenIcon size={20} />
                        </Button>
                      </TableCell>
                      <TableCell style={{padding:"10px"}}>
                        {moment(extract.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell style={{padding:"10px"}}>
                        {extract.expertFirstName} {extract.expertLastName}
                      </TableCell>
                      <TableCell style={{textAlign:'center', paddingLeft:'0px', paddingRight:'0px',}}>
                        {extract.status === "waiting" && <font color="orange">En attente</font>}
                        {(extract.status === "todo" && extract.progressModif <= 0) && <font color="blue">À traiter</font>}
                        {(extract.status === "todo" && extract.progressModif > 0) && <font color="blue">En traitement<br/>({extract.progressModif.toFixed(2)}%)</font>}
                        {extract.status === "ok" && <font color="green">Traité</font>}
                        {extract.status === "error" && <font color="red">Erreur</font>}
                      </TableCell>
                      <TableCell style={{textAlign:'center', paddingLeft:'0px', paddingRight:'0px',}}>
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          style={{height:'31px', minWidth:'0px'}}
                          disabled={extract.status !== "todo"}
                          onClick={() => {
                            setDialogEdit(extract)
                          }}
                        >
                          <EditIcon size={20} />
                        </Button>
                      </TableCell>
                      <TableCell style={{textAlign:'center', paddingLeft:'0px', paddingRight:'0px'}}>
                        <Button
                          color="primary"
                          variant="text"
                          size="small"
                          style={{height:'31px', minWidth:'0px'}}
                          disabled={extract.status === "error" || extract.status === "waiting"}
                          onClick={() => {
                            setDialogGraph(extract)
                          }}
                        >
                          <PieChartIcon size={20} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Card>
      </Box>

      <MyModal
        open={dialogAddExtract}
        onClose={() => {
          setDialogAddExtract(false);
        }}
        title="Ajouter une extraction"
        validButton={false}
      >
        <>
          <Formik
            initialValues={{
              name: '',
              approche : 'semantic',
              file : null,
            }}
            validationSchema={
              Yup.object().shape({
                name: Yup.string().max(255).required('Le nom est requis'),
                file: Yup.mixed().required('Le fichier est requis'),
              })
            }
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setProgressAdd(0)
              const currentDate = new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              ).toISOString()
              const formData = new FormData();
              formData.append('file', values.file);
              formData.append('name', values.name);
              formData.append('approche', values.approche)
              formData.append('createdAt', currentDate)
              APIS.addExtraction(formData, onUploadProgress).then(result => {
                if (result && result.message === "addExtraction") {
                  getExtractions();
                  setIsRefreshing(!isRefreshing);
                  setSubmitting(false);
                  setDialogAddExtract(false);
                  setSearchInput("")
                  setProgressAdd(0)
                } else {
                  alert("Error")
                  setProgressAdd(0)
                }
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Son Nom"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                  size='small'
                  required
                />
                <TextField
                  error={Boolean(touched.approche && errors.approche)}
                  fullWidth
                  helperText={touched.approche && errors.approche}
                  label="Approche"
                  name="approche"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.approche}
                  variant="outlined"
                  size='small'
                  required
                  sx={{marginTop:"10px"}}
                >
                  <option value="semantic">Sémantique</option>
                  <option value="zeroshot">Zéro-shot</option>
                </TextField>
                <Button
                  fullWidth
                  disabled={values.file !== null}
                  variant="outlined"
                  component="label"
                  sx={{marginTop:"15px"}}
                >
                  {values.file === null &&
                    <>Sélectionner un fichier PDF *</>
                  }
                  {values.file !== null &&
                    values?.file?.name
                  }
                  <input
                    type="file"
                    name="file"
                    accept="application/pdf"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    hidden
                  />
                </Button>
                {Boolean(touched.file && errors.file) &&
                  <Box sx={{color:"#d32f2f", fontSize:"0.75rem", marginTop:"4px", marginLeft:"14px"}}>{errors.file}</Box>
                }
                <Box sx={{ py: 2, marginTop:"20px" }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    AJOUTER {isSubmitting && <>{progressAdd} %</>}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </>
      </MyModal>

      <MyModal
        open={dialogGraph !== false}
        onClose={() => {
          setDialogGraph(false);
        }}
        title={dialogGraph !== false ? "Graph \""+dialogGraph?.name+"\" (données initiales)" : "Graph (données initiales)"}
        closeText="Fermer"
        maxWidth="md"
        overflow="hidden"
        validButton={false}
      >
        <Box sx={{height:"calc(100vh - 200px)"}}>
          {dialogGraph !== false &&
            <>
              {dialogGraphResult === null && <Box sx={{textAlign:"center", color:"red", fontWeight:"bold"}}>Erreur</Box>}
              {dialogGraphResult !== null &&
                <Radar
                  data={{
                    labels: getLabelForGraph(Object.keys(dialogGraphResult?.firstResult?.profile)),
                    datasets: [
                      {
                        label: "SoftSkills",
                        backgroundColor: "rgba(34, 202, 236, .2)",
                        borderColor: "rgba(34, 202, 236, 1)",
                        pointBackgroundColor: "rgba(34, 202, 236, 1)",
                        poingBorderColor: "#fff",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "rgba(34, 202, 236, 1)",
                        data: Object.values(dialogGraphResult?.firstResult?.profile)
                      }
                    ]
                  }}
                  options={{
                    maintainAspectRatio : false,
                    scale: {
                      ticks: {
                        min: 0,
                        max: 16,
                        stepSize: 2,
                        showLabelBackdrop: false,
                        backdropColor: "rgba(203, 197, 11, 1)"
                      },
                      angleLines: {
                        color: "rgba(255, 255, 255, .3)",
                        lineWidth: 1
                      },
                      gridLines: {
                        color: "rgba(255, 255, 255, .3)",
                        circular: true
                      }
                    }
                  }}
                />
              }
            </>
          }
        </Box>
      </MyModal>


      <MyModal
        open={dialogEdit !== false}
        onClose={() => {
          setDialogEdit(false);
        }}
        onValid={() => {
          APIS.validateResult({extractionID : dialogEdit.id, modifResult : JSON.stringify(dialogEditResult?.modifResult)}).then(result => {
            if (result && result.message === "validateResult") {
              getExtractions();
              setIsRefreshing(!isRefreshing);
              setDialogEdit(false);
              setSearchInput("")
            } else {
              alert("Error")
            }
          })
        }}
        title={getDialogEditTitle()}
        closeText="Fermer"
        maxWidth="md"
        overflow="auto"
        disabledValid={extractionsNoFiltered?.find((el) => el.id === dialogEdit.id)?.progressModif !== 100}
        validText="Envoyer"
      >
        <Box>
          {dialogEdit !== false &&
            <>
              {dialogEditResult === null && <Box sx={{textAlign:"center", color:"red", fontWeight:"bold"}}>Erreur</Box>}
              {dialogEditResult !== null &&
                <Box>
                  <Box sx={{display:"flex"}}>
                    <Box sx={{padding:"10px", flex : 0.4, borderRight:"1px solid black"}}>
                      <Box sx={{position:"sticky", top:"10px"}}>
                        {dialogEditResult?.firstResult?.blocs[dialogEditResult?.edition?.actIndex]?.sent}
                      </Box>
                    </Box>
                    <Box sx={{padding:"10px", flex : 0.6}}>
                      <Box sx={{marginBottom:"15px", display:"flex", gap:"15px", alignItems:"center", justifyContent:"center"}}>
                        <Box sx={{width:"200px", textAlign:"center"}}></Box>
                        <Box sx={{width:"60px", textAlign:"center"}}><i>Algo</i></Box>
                        <Box sx={{width:"75px", textAlign:"center"}}><i>Correction</i></Box>
                        <Box sx={{width:"30px", textAlign:"center"}}></Box>
                      </Box>
                      {Object.keys(dialogEditResult?.firstResult?.blocs[dialogEditResult?.edition?.actIndex]?.profile).map((profile, i) => {
                        let isActive = dialogEditResult?.modifResult[dialogEditResult?.edition?.actIndex]?.profile[profile] === undefined ? false : true
                        let displayName = skillsDefs?.find((el) => el.name === profile)?.title
                        return (
                          <Box sx={{marginTop:"5px", marginBottom:"5px", display:"flex", gap:"15px", alignItems:"center", justifyContent:"center"}} key={i}>
                            <Box sx={{opacity:isActive ? 1 :0.4, width:"200px", fontWeight:"bold", textTransform: "capitalize", textAlign:"right"}}>
                              {displayName}
                            </Box>
                            <TextField
                              variant="outlined"
                              size="small"
                              type="number"
                              disabled
                              sx={{
                                width:"60px",
                                ".MuiInputBase-input" : {
                                  padding:"5px",
                                  textAlign:"right",
                                },
                                "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button" : {
                                  WebkitAppearance: "none",
                                  margin: 0,
                                },
                                "input[type=number]": {
                                  MozAppearance: "textfield",
                                }

                              }}
                              value={dialogEditResult?.firstResult?.blocs[dialogEditResult?.edition?.actIndex]?.profile[profile]}
                            />
                            <TextField
                              variant="outlined"
                              size="small"
                              type="number"
                              disabled={!isActive}
                              inputProps={{
                                step: 0.01,
                                max : 1,
                                min : 0,
                              }}
                              onChange={(e) => {
                                let newVal = e.target.value
                                if (newVal === "") { newVal = 0}
                                if (newVal > 1) { newVal = 1}
                                if (newVal < 0) { newVal = 0}
                                newVal = parseFloat(newVal)
                                let newObj = JSON.parse(JSON.stringify(dialogEditResult))
                                newObj.modifResult[dialogEditResult?.edition?.actIndex].profile[profile] = newVal
                                setDialogEditResult(newObj)
                              }}
                              sx={{
                                width:"60px",
                                marginLeft:"7.5px",
                                marginRight:"7.5px",
                                ".MuiInputBase-input": {
                                  padding:"5px",
                                  textAlign:"right",
                                },
                              }}
                              value={isActive ? dialogEditResult.modifResult[dialogEditResult.edition.actIndex].profile[profile] : dialogEditResult.firstResult.blocs[dialogEditResult.edition.actIndex].profile[profile]}
                            />
                            {isActive ? (
                              <Button
                                color="primary"
                                variant="text"
                                size="small"
                                style={{height:'31px', minWidth:'0px'}}
                                onClick={() => {
                                  let newObj = JSON.parse(JSON.stringify(dialogEditResult))
                                  delete newObj.modifResult[dialogEditResult?.edition?.actIndex].profile[profile]
                                  setDialogEditResult(newObj)
                                }}
                              >
                                <TrashIcon size={20} />
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="text"
                                size="small"
                                style={{height:'31px', minWidth:'0px'}}
                                onClick={() => {
                                  let newObj = JSON.parse(JSON.stringify(dialogEditResult))
                                  newObj.modifResult[dialogEditResult?.edition?.actIndex].profile[profile] = dialogEditResult?.firstResult?.blocs[dialogEditResult?.edition?.actIndex]?.profile[profile]
                                  setDialogEditResult(newObj)
                                }}
                              >
                                <PlusCircleIcon size={20} />
                              </Button>
                            )}
                          </Box>
                        )
                      })}
                      <Box sx={{marginTop:"10px", display:"flex", gap:"15px", alignItems:"center", justifyContent:"center"}}>
                        <Box sx={{width:"200px", textAlign:"center"}}></Box>
                        <Button
                          disabled={Object.keys(dialogEditResult?.firstResult?.blocs[dialogEditResult?.edition?.actIndex]?.profile).length === skillsDefs.length}
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setDialogAddSkill(true)
                          }}
                        >
                          + Ajouter
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{marginTop:"30px", display:"flex", gap:"30px", justifyContent:"flex-end"}}>
                    <Button
                      disabled={dialogEditResult?.edition?.actIndex === 0}
                      variant="contained"
                      onClick={() => {
                        let newVal = JSON.parse(JSON.stringify(dialogEditResult))
                        newVal.edition.actIndex = dialogEditResult.edition.actIndex-1
                        setDialogEditResult(newVal)
                      }}
                    >
                      Précédent
                    </Button>
                    {(dialogEditResult?.edition?.actIndex === dialogEditResult?.edition?.nbrBlocs-1) ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          let newVal = JSON.parse(JSON.stringify(dialogEditResult))
                          newVal.edition.actIndex = parseInt(dialogEditResult.edition.actIndex)+1
                          let newArr = JSON.parse(JSON.stringify(extractionsNoFiltered))
                          let find = newArr.find((el) => el.id === dialogEdit.id)
                          APIS.saveResult({extractionID : dialogEdit.id, actProgress : find?.progressModif, actIndex : newVal.edition.actIndex, modifLength : newVal?.modifResult?.length, modifResult : JSON.stringify(newVal?.modifResult)}).then(result => {
                            if (result && result.message === "saveResult") {
                              let newArr = JSON.parse(JSON.stringify(extractionsNoFiltered))
                              let find = newArr.find((el) => el.id === dialogEdit.id)
                              if (find) {
                                find.progressModif = result.progressPercent
                              }
                              setExtractionsNoFiltered(newArr)
                            } else {
                              alert("Error")
                            }
                          })
                        }}
                      >
                        Enregistrer
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => {
                          let newVal = JSON.parse(JSON.stringify(dialogEditResult))
                          newVal.edition.actIndex = parseInt(dialogEditResult.edition.actIndex)+1
                          setDialogEditResult(newVal)
                          let newArr = JSON.parse(JSON.stringify(extractionsNoFiltered))
                          let find = newArr.find((el) => el.id === dialogEdit.id)
                          APIS.saveResult({extractionID : dialogEdit.id, actProgress : find?.progressModif, actIndex : newVal.edition.actIndex, modifLength : newVal?.modifResult?.length, modifResult : JSON.stringify(newVal?.modifResult)}).then(result => {
                            if (result && result.message === "saveResult") {
                              let newArr = JSON.parse(JSON.stringify(extractionsNoFiltered))
                              let find = newArr.find((el) => el.id === dialogEdit.id)
                              if (find) {
                                find.progressModif = result.progressPercent
                              }
                              setExtractionsNoFiltered(newArr)
                            } else {
                              alert("Error")
                            }
                          })
                        }}
                      >
                        Suivant
                      </Button>
                    )}
                  </Box>
                </Box>
              }
            </>
          }
        </Box>
      </MyModal>

      <MyModalSmall
        open={dialogAddSkill}
        title="Ajouter un skill"
        closeText="Annuler"
        validText="Ajouter"
        onClose={() => {
          setDialogAddSkill(false)
        }}
        onValid={() => {
          setDialogAddSkill(false)
          let newObj = JSON.parse(JSON.stringify(dialogEditResult))
          newObj.firstResult.blocs[dialogEditResult.edition.actIndex].profile[selectAddSkill] = 0
          newObj.modifResult[dialogEditResult.edition?.actIndex].profile[selectAddSkill] = 0
          setDialogEditResult(newObj)
        }}
      >
        <Box>
          <TextField
            fullWidth
            label="Skill"
            onChange={(e) => {
              setSelectAddSkill(e.target.value)
            }}
            select
            SelectProps={{ native: true }}
            value={selectAddSkill}
            variant="outlined"
            sx={{
              marginTop:"10px",
              ".MuiNativeSelect-select" : {
                textTransform:"capitalize"
              }
            }}
          >
            {skillsDispo.map((skill, index) => {
              return (
                <option key={index} style={{textTransform:"capitalize"}} value={skill.name}>{skill.title}</option>
              )
            })}
          </TextField>
        </Box>
      </MyModalSmall>


    </>
  );
};


export default ExtractionsListResults;

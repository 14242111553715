import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Modal from "@material-ui/core/Modal"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const MyModalSmall = ({ open, onClose, onValid, title, text, validText, validButton, closeText, children, disabledValid }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"xs"}>
      <DialogTitle style={{fontSize:'18px'}}>{title}</DialogTitle>
      <DialogContent style={{paddingTop:"5px"}}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{closeText}</Button>
        {validButton !== false &&
          <Button disabled={disabledValid} onClick={onValid}>{validText}</Button>
        }
      </DialogActions>
    </Dialog>
  )
}

MyModalSmall.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default MyModalSmall

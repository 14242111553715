import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';

import { setUser } from "../utils/auth"
import APIS from "../utils/APIS"

const Login = () => {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState()

  return (
    <>
      <Helmet>
        <title>Connexion | RisingUp Certif</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().max(255).required('Veuillez renseigner votre email'),
              password: Yup.string().max(255).required('Veuillez renseigner votre mot de passe')
            })}
            onSubmit={(values, { setSubmitting }) => {
              setAlertMessage("");
              const user = { username: values.email, password : values.password, type:"RisUpCertifTypeKey" }
              APIS.postUserLogin(user).then(result => {
                if (!result.err) {
                  if (result.data.message == "signin") {
                    setUser(result.data.user)
                    navigate("/")
                    //console.log("connection réussi");
                  } else {
                    if (result.data.message === "wrongPassword") {
                      setAlertMessage("Le mot de passe est incorrect");
                    } else if (result.data.message === "wrongUsername") {
                      setAlertMessage("L'adresse email est incorrecte")
                    } else if (result.data.message === "wrongDatas") {
                      setAlertMessage("Le mot de passe est incorrect")
                    } else {
                      setAlertMessage("Identifiant ou mot de passe incorrect")
                    }
                    setSubmitting(false);
                  }
                } else {
                  setAlertMessage("Erreur inconue");
                  setSubmitting(false);
                }
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Connexion
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Connectez-vous à notre interface
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Adresse Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Mot de passe"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    SE CONNECTER
                  </Button>
                </Box>
                {alertMessage && alertMessage.length > 0 && (
                  <Box
                    sx={{
                      pb: 1,
                      pt: 0
                    }}
                  >
                    <Typography
                      align="left"
                      color="red"
                      variant="body1"
                    >
                      {alertMessage}
                    </Typography>
                  </Box>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;

import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from './Logo';

import { getCurrentUser, logout } from "../utils/auth"
import getInitials from '../utils/getInitials';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();

  const currentUser = getCurrentUser()

  return (
    <AppBar
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden smDown>
          <Box sx={{fontWeight:"bold"}}>
            {currentUser?.firstName} {currentUser?.lastName}
          </Box>
        </Hidden>
        <Hidden smUp>
          <Box sx={{fontWeight:"bold"}}>
            {currentUser?.firstName} {getInitials(currentUser?.lastName)}
          </Box>
        </Hidden>
        <Hidden lgDown>
          {/*<IconButton color="inherit" size="large">
            Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>*/}
          <IconButton color="inherit" size="large" onClick={() => {
            logout(() => navigate("/"))
          }}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;

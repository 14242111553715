import APIS from "./APIS"

const isBrowser = typeof window !== `undefined`

export const setUser = user =>
  (window.localStorage.RisUpCertifUser = JSON.stringify(user))

const getUser = () => {
  if (window.localStorage.RisUpCertifUser) {
    let user = JSON.parse(window.localStorage.RisUpCertifUser)
    return user ? user : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  if (user && user.username) {
    return user.username
  } else if (user && user.email) {
    return user.email
  }
}

export const logout = callback => {
  if (!isBrowser) return
  //renewSocket();
  setUser({})
  APIS.getLoggedOut().then(result => {
    callback()
  })
}


export const getCurrentUser = () => isBrowser && getUser()

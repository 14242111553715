import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Modal from "@material-ui/core/Modal"

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const MyModal = ({ open, onClose, onValid, title, text, validText, children, disabledValid, validButton, closeButton, closeText, maxWidth, overflow }) => {
  let textForClose = "Annuler"
  if (closeText) { textForClose = closeText }
  let setMaxWidth = "sm"
  if (maxWidth) { setMaxWidth = maxWidth }
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={setMaxWidth}>
      <DialogTitle style={{fontSize:'18px'}}>{title}</DialogTitle>
      <DialogContent style={{overflow : overflow, paddingBottom: closeButton=== false && validButton === false ? "0px" : "20px", paddingTop:"5px"}}>
        {children}
      </DialogContent>
      {closeButton === false && validButton === false ? (
        <></>
      ) : (
        <DialogActions>
          {closeButton !== false &&
            <Button onClick={onClose}>{textForClose}</Button>
          }
          {validButton !== false &&
            <Button disabled={disabledValid} onClick={onValid}>{validText}</Button>
          }
        </DialogActions>
      )}
    </Dialog>
  )
}

MyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default MyModal

import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import AccountProfileDetails from '../components/account/AccountProfileDetails';
import SettingsPassword from '../components/settings/SettingsPassword';

const Account = () => (
  <>
    <Helmet>
      <title>Mon compte | RisingUp Certif</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >

      <Container maxWidth="lg">
        <AccountProfileDetails />
        <Box sx={{ pt: 3 }}>
          <SettingsPassword />
        </Box>
      </Container>
    </Box>
  </>
);

export default Account;

import axios from "axios"


export const glb_url = process.env.REACT_APP_APIS_BACK || "https://localhost:443"


export default {
  /* DEBUT PASSPORTJS */
  getLoginStatus: async () => {
    try {
      const res = await axios.get(glb_url + "/auth/status", {
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },

  postUserLogin: async user => {
    try {
      const res = await axios.post(glb_url + "/auth/signin", user, {
        withCredentials: true,
      })
      return {
        err: false,
        data: res.data || "error logging in",
      }
    } catch (err) {
      return {
        err: true,
        data: false,
      }
    }
  },

  getLoggedOut: async () => {
    try {
      const res = await axios.get(glb_url + "/auth/signout", {
        withCredentials: true,
      })
      return res
    } catch (err) {
      return console.log(err)
    }
  },

  postSetPsswd: async vals => {
    try {
      const res = await axios.post(glb_url + "/users/setPsswd", vals, {
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },

  addAccount: async vals => {
    try {
      const res = await axios.post(glb_url + "/users/add", vals, {
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },
  /* FIN PASSPORTJS */


  /* DEBUT EXTRACTIONS */
  addExtraction: async (vals, onUploadProgress) => {
    try {
      const res = await axios.put(glb_url + "/extractions/add", vals, {
        onUploadProgress : onUploadProgress,
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },

  getExtractions: async vals => {
    try {
      const res = await axios.post(glb_url + "/extractions", vals, {
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },

  getResultByID : async vals => {
    try {
      const res = await axios.post(glb_url + "/extractions/getResultByID", vals, {
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },

  saveResult : async vals => {
    try {
      const res = await axios.post(glb_url + "/extractions/saveResult", vals, {
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },

  validateResult : async vals => {
    try {
      const res = await axios.post(glb_url + "/extractions/validateResult", vals, {
        withCredentials: true,
      })
      return res.data
    } catch (err) {
      return console.log(err)
    }
  },
  /* FIN EXTRACTIONS */
}
